<template>
  <section>
    <div v-bind="initData" class="formContainer jobsForm-section section">
      <JobsHero />
      <form id="candidate-data-form" enctype="multipart/form-data" class="form-data candidateForm" v-if="info" :key="formKey">
        <div class="candidateForm-container">
          <h2 class="candidateForm-title">{{ vacancy.title }}</h2>
          <div class="form-data-input">
            <div role="label" required>{{ info.optionID.label }}</div>
            <div class="select-wrap">
              <select name="documentType" placeholder="Tipo de documento" required @blur="validateData">
                <option v-for="(value, key) in $global.dictionary.documentType" :key="key" :value="key">{{ value }}</option>
              </select>
            </div>
          </div>
          <div class="form-data-input">
            <div role="label" required>{{ info.inputID.label }}</div>
            <input type="number" name="document" required :placeholder="info.inputID.placeholder" @blur="validateData" />
          </div>
          <div class="form-data-input">
            <div role="label" required>{{ info.inputOnlyName.label }}</div>
            <input type="text" name="name" required :placeholder="info.inputOnlyName.placeholder" @blur="validateData" />
          </div>
          <div class="form-data-input">
            <div role="label" required>{{ info.inputLastName.label }}</div>
            <input type="text" name="lastName" required :placeholder="info.inputLastName.placeholder" @blur="validateData" />
          </div>
          <div class="form-data-input">
            <div role="label" required>ciudad</div>
            <div role="select-radio" required :placeholder="info.optionCity.label" @click="$validate.validateOption('#youCity')" id="youCity">
              <div role="option">
                <input type="radio" id="fromCity2" name="city" value="medellin" />
                <label for="fromCity2">{{ info.optionCity.option2 }}</label>
              </div>
              <div role="option">
                <input type="radio" id="fromCity3" name="city" value="pereira" />
                <label for="fromCity3">{{ info.optionCity.option3 }}</label>
              </div>
              <div role="option">
                <input type="radio" id="fromCity4" name="city" value="cucuta" />
                <label for="fromCity4">{{ info.optionCity.option4 }}</label>
              </div>
              <div role="option">
                <input type="radio" id="fromCity1" name="city" value="bogota" />
                <label for="fromCity1">{{ info.optionCity.option1 }}</label>
              </div>
              <div role="option" class="display-flex flex-middle">
                <input type="radio" id="fromCity5" name="city" value="other" />
                <label for="fromCity5" w100 @click="selectChechbox('#fromCity5')">
                  <input role="other" name="otherCity" type="text" :placeholder="info.optionCity.optionOther" />
                </label>
              </div>
            </div>
          </div>

          <div class="form-data-input">
            <div role="label" required>{{ info.inputEmail.label }}</div>
            <input type="email" name="email" required :placeholder="info.inputEmail.placeholder" @blur="validateData" />
          </div>
          <div class="form-data-input">
            <div role="label" required>{{ info.inputPhone.label }}</div>
            <input type="tel" name="phoneNumber" required :placeholder="info.inputPhone.placeholder" />
          </div>

          <div class="form-data-input">
            <div role="label" required>{{ info.inputCurriculum.label }}</div>
            <input
              type="file"
              name="curriculum"
              required
              :placeholder="info.inputCurriculum.placeholder"
              accept="application/pdf,.doc,.docx,application/msword"
            />
          </div>

          <div class="candidateForm-briefcaseRadios form-data-input" role="select-radio">
            <div role="label">{{ info.briefcase.label }}</div>
            <div class="candidateForm-containerRow" role="option">
              <input type="radio" name="typebriefcase" value="file" v-model="typeBriefcase" checked />
              <label>{{ info.briefcaseOptions.option1 }}</label>
            </div>
            <div class="candidateForm-containerRow" role="option">
              <input type="radio" name="typebriefcase" value="links" v-model="typeBriefcase" />
              <label>{{ info.briefcaseOptions.option2 }}</label>
            </div>
          </div>

          <div class="form-data-input" v-if="typeBriefcase === 'file'">
            <input type="file" id="briefcase" :placeholder="info.briefcase.placeholder" accept="application/pdf,video/*" />
          </div>

          <div class="candidateForm-multipleLinks" v-if="typeBriefcase === 'links'">
            <inputAdd class="candidateForm-inputAdd" @addValue="onAddLink" />
          </div>

          <div class="candidateForm-actions">
            <div class="candidateForm-actions__submit">
              <div class="form-data-input">
                <input type="nosubmit" :value="info.buttonSubmit.label" @click="dataSendConfirm" readonly />
              </div>
            </div>
            <div class="candidateForm-actions__cancel">
              <div class="form-data-input">
                <input type="cancel" :value="info.buttonCancel.label" @click="cancelSend" readonly />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import JobsHero from "./jobsHero.vue";
import alerts from "../../assets/js/swalAlerts";
import api from "../../api";
import inputAdd from "@/components/inputs/InputAdd.vue";

export default {
  components: { JobsHero, inputAdd },
  data: function () {
    return {
      info: null,
      formKey: 0,
      vacancy: {},
      typeBriefcase: "file",
      briefcaseLinks: [],
      hasBriefcase: false,
    };
  },
  methods: {
    selectChechbox: function (selector) {
      document.querySelector(selector).checked = true;
    },
    getLanguage: async function () {
      let { data } = await this.$api.get(`${process.env.VUE_APP_API_HOST}signupLanguages`);
      this.info = data;
    },
    validateData: function (event) {
      return this.$validate.validateInput(event, { type: "file" });
    },
    isDataValid: function () {
      const isValidForm = this.$validate.validateForm("#candidate-data-form", {
        type: "file",
      });
      if (isValidForm) {
        return true;
      }
    },
    cancelSend: function () {
      this.$router.push({ path: "/jobs" }).catch(() => {});
    },
    onAddLink: function (links) {
      this.briefcaseLinks = links;
    },
    dataSend: async function () {
      try {
        const _host = `${process.env.VUE_APP_API_ADMIN}candidate`;
        const _data = new FormData(document.getElementById("candidate-data-form"));
        _data.append("vacancy", this.$route.params.id);
        _data.append("state", "new");
        if (this.typeBriefcase === "links") {
          _data.append("briefcaseLinks", JSON.stringify(this.briefcaseLinks.map((value) => ({ type: "link", link: value }))));
        }
        const isBriefcase = document.getElementById("briefcase") ? document.getElementById("briefcase").files : [];
        if (isBriefcase.length) {
          _data.append("briefcase", isBriefcase[0]);
        }

        alerts.dataSending({
          title: this.info.dataSending.title,
          message: this.info.dataSending.text,
        });
        await api.post(_host, _data);

        alerts.dataSendSuccess({
          title: this.info.dataSendSuccess.title,
          message: this.info.dataSendSuccess.text,
          buttonText: this.info.dataSendSuccess.confirmButtonText,
        });
        this.briefcaseLinks = [];
        this.typeBriefcase = "file";
        this.formKey++;
      } catch (error) {
        alerts.dataSendError({
          title: this.info.dataSendError.title,
          message: this.info.dataSendError.text,
          buttonText: this.info.dataSendError.confirmButtonText,
        });
      }
    },
    dataSendConfirm: async function () {
      this.$validate.validateOption("#youCity");
      if (!this.isDataValid()) return;

      alerts
        .dataSendConfirm({
          title: this.info.dataSubmitConfirm.title,
          message: this.info.dataSubmitConfirm.text,
          buttonTextYes: this.info.dataSubmitConfirm.confirmButtonText,
          buttonTextNo: this.info.dataSubmitConfirm.cancelButtonText,
        })
        .then(({ isConfirmed }) => {
          if (!isConfirmed) return;

          this.dataSend();
        });
    },
    getVacancy: async function () {
      if (this.$route.params.id) {
        const { data } = await api.get(`${process.env.VUE_APP_API_ADMIN}vacancy/${this.$route.params.id}`);
        this.vacancy = data;
      }
    },
  },
  computed: {
    initData: async function () {
      await this.getLanguage();
      await this.getVacancy();
    },
  },
  mounted: async function () {
    this.$root.$on("refreshlang", async (data) => {
      await this.getLanguage();
      await this.getVacancy();
    });
  },

  async beforeRouteEnter(to, from, next) {
    const { data } = await api.get(`${process.env.VUE_APP_API_ADMIN}vacancy/${to.params.id}`);

    if (data.title.toLowerCase().trim() === "model" || data.title.toLowerCase().trim() === "modelo") {
      next("/signup");
      return;
    }

    next();
  },
};
</script>

<style lang="scss">
#youCity {
  padding: 5px;
  min-width: 129px;
  border-radius: $mradius;
  border: 1px solid #bfbfbf;
}
.formContainer {
  padding: $mpadding;
  margin-top: 30px;
}
.candidateForm {
  padding: 24px $mpadding;
  &-title {
    font-family: $sec_font;
    color: $primary_color;
    margin-bottom: 10px;
  }

  &-container {
    background: $white;
    box-shadow: $form_shadow;
    padding: $form_padding;
    border-radius: $mradius;
    margin: 0 auto;
    max-width: 401px;

    &-submit {
      padding: $mpadding 0;
    }
  }
  &-containerRow {
    gap: 5px;
  }
  &-inputAdd {
    .inputAdd__listItem {
      *[overflowText] {
        width: 190px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @media screen and (min-width: $mobile_width - 50px) {
          width: 250px;
        }
        @media screen and (min-width: $mobile_width) {
          width: 290px;
        }
        @media screen and (min-width: $pocket_width) {
          width: 330px;
        }
      }
    }
    .inputAddDouble__btnDelete {
      @include Flex(row);
      width: 20px;
      height: 20px;
      padding: 4px;
      border-radius: 6px;
      background-color: $primary_color;
      border: none;
      cursor: pointer;
      color: $white;
    }
  }
  &-actions {
    display: flex;
    gap: 6px;
    margin-top: 24px;
    justify-content: center;
    input {
      font-weight: bold;
      padding: 0;
      min-width: 129px;
      border-radius: $mradius;
    }
  }
  input[type="file"]::before {
    font-weight: normal;
    font-size: 15px;
  }
}
</style>
